import React from "react"

import TrialRequestForm from "components/forms/TrialRequestForm"
import SEO from "components/seo"

const TrialRequestPage: React.FC = () => {
  return (
    <>
      <SEO title="β版事前登録" />
      <TrialRequestForm />
    </>
  )
}

export default TrialRequestPage
