import { navigate } from "gatsby"
import React, { useState } from "react"

import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import TextField from "@material-ui/core/TextField"

import * as analytics from "analytics"
import Footer from "components/layout/Footer"
import Header from "components/layout/Header"

const TrialRequestForm: React.FC = () => {
  const [success, setSuccess] = useState(false)

  const requestTrial = async () => {
    analytics.requestTrial()
    setSuccess(true)
  }

  return (
    <>
      <Header />
      <div className="flex justify-center py-20 bg-background-color">
        <div className="flex flex-col items-center w-full max-w-5xl px-4">
          <div className="text-3xl font-bold text-center">
            <h1>
              <span>β版の利用を</span>
              <span>ご希望いただき</span>
              <span>ありがとうございます！</span>
            </h1>
          </div>
          <div className="py-8 text-lg text-center">
            ご記入いただいた内容を元にβ版提供開始時にご案内させていただきます。
          </div>

          <iframe
            className="hidden"
            name="dummy-form"
            onLoad={() => {
              if (success) {
                navigate("/confirm?request=trial")
              }
            }}
          />
          <form
            className="flex flex-col items-start w-full max-w-5xl"
            action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScJ2sOi1-UZYV4TeARGw-rFFYoXdQyMsjNs9SdhcMu3tN_P5w/formResponse"
            method="post"
            target="dummy-form"
            onSubmit={requestTrial}
          >
            <div className="w-full mb-2 text-sm text-secondary">
              氏名 <span className="text-xs text-yellow">※ 必須</span>
            </div>
            <TextField
              className="w-full mb-4 bg-white"
              variant="outlined"
              required
              name="entry.1928021246"
            />
            <div className="w-full mb-2 text-sm text-secondary">
              会社名 <span className="text-xs text-yellow">※ 必須</span>
            </div>
            <TextField
              className="w-full mb-4 bg-white"
              variant="outlined"
              required
              name="entry.870004164"
            />
            <div className="w-full mb-2 text-sm text-secondary">
              部署名 <span className="text-xs text-yellow">※ 必須</span>
            </div>
            <TextField
              className="w-full mb-4 bg-white"
              variant="outlined"
              required
              name="entry.1381494399"
            />
            <div className="w-full mb-2 text-sm text-secondary">
              役職 <span className="text-xs text-yellow">※ 必須</span>
            </div>
            <TextField
              className="w-full mb-4 bg-white"
              variant="outlined"
              required
              name="entry.187854266"
            />
            <div className="w-full mb-2 text-sm text-secondary">
              メールアドレス <span className="text-xs text-yellow">※ 必須</span>
            </div>
            <TextField
              className="w-full mb-8 bg-white"
              variant="outlined"
              required
              type="email"
              name="entry.1755737601"
            />

            <div className="my-2 text-lg text-secondary">
              LEONADEについてどこで知りましたか?{" "}
              <span className="text-sm text-yellow">※ 複数選択可</span>
            </div>
            <FormGroup className="pl-4 mb-4">
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="entry.1695057993"
                    value="検索してみつけた"
                  />
                }
                label="検索してみつけた"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="entry.1695057993"
                    value="知人等から教えてもらった"
                  />
                }
                label="知人等から教えてもらった"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="entry.1695057993"
                    value="aifie公式HPで知った"
                  />
                }
                label="aifie公式HPで知った"
              />
              <TextField
                className="max-w-full mb-2 w-80"
                name="entry.1695057993.other_option_response"
                placeholder="その他"
              />
              {/* その他の内容を送信するのに必要 */}
              <input
                className="hidden"
                name="entry.1695057993"
                readOnly
                value="__other_option__"
              />
            </FormGroup>

            <div className="my-2 text-lg text-secondary">
              LEMONADE 正式版導入のご検討意思をお聞かせ下さい。
            </div>
            <RadioGroup className="pl-4 mb-4">
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    name="entry.2021264580"
                    value="提供開始後すぐにでも導入したい"
                  />
                }
                label="提供開始後すぐにでも導入したい"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    name="entry.2021264580"
                    value="α版で試してみてから考える"
                  />
                }
                label="試してみてから考える"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    name="entry.2021264580"
                    value="導入の予定はない"
                  />
                }
                label="導入の予定はない"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    name="entry.2021264580"
                    value="わからない"
                  />
                }
                label="わからない"
              />
            </RadioGroup>

            <div className="my-2 text-lg text-secondary">
              LEMONADEのご利用想定アカウント数をお選び下さい。
            </div>
            <RadioGroup className="pl-4 mb-6">
              <FormControlLabel
                control={
                  <Radio color="secondary" name="entry.26394023" value="1~10" />
                }
                label="1~10"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    name="entry.26394023"
                    value="11~30"
                  />
                }
                label="11~30"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    name="entry.26394023"
                    value="30~50"
                  />
                }
                label="30~50"
              />
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    name="entry.26394023"
                    value="51以上"
                  />
                }
                label="51以上"
              />
              <FormControlLabel
                control={
                  <Radio color="secondary" name="entry.26394023" value="不明" />
                }
                label="不明"
              />
            </RadioGroup>

            <div className="w-full mb-2 text-sm text-secondary">
              ご質問ご不明点が御座いましたらご記入下さい。
            </div>
            <TextField
              className="w-full bg-white"
              variant="outlined"
              multiline
              rows={5}
              rowsMax={9}
              name="entry.903172373"
            />
            <div className="flex flex-col items-center w-full mt-20">
              <Button
                className="px-8 py-3 text-xl rounded-none text-secondary"
                variant="contained"
                color="primary"
                type="submit"
                disabled={success}
              >
                上記内容でβ版利用を申し込む
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TrialRequestForm
